<template>
<div>
  <div class="container-fluid">
    <PageTitle :noAdd="true" :singlePage="true" />

    <template v-if="isList">
      <b-overlay :show="!dataList">
        <card-list 
          :filter.sync="filter"
          :dataListing.sync="data"
          :idKey="idKey"
          :fields="fields"
          
          :no-multi-select="true"
          :no-btn-left="true"
          :no-btn-mass="true"
        > 
          <template #filters>
            <b-col md=4>
              <DatePicker 
                v-model="filter.date"
                type="date" 
                range 
                @input="doFilter"
                value-type="DD-MM-YYYY"
                style="width: 100%;"
                input-class="form-control"
                placeholder="Pilih Tanggal"
              />
            </b-col>
            <b-col md=3>
              <VSelect 
                v-model="filter.status" 
                placeholder="-- Semua Status --"
                :options="mrStatus" 
                :reduce="v=>String(v.value)" 
                @input="doFilter"
              />
            </b-col>
          </template>
          <template #trkm_mitigasi_biaya="data">
            Rp {{(data.scope.value||0).format()}}
          </template>
          <template #jadwal="data">
            {{ data.scope.item.trkm_mitigasi_jadwal_awal | moment('DD MMM YYYY') }} - {{ data.scope.item.trkm_mitigasi_jadwal_akhir | moment('DD MMM YYYY') }}
          </template>
          <template #trkm_hasil_input_date="data">
            <b-badge
              v-if="data.scope.item.trkm_hasil_status=='Y'"
              variant="success"
            >
              Sudah Dilakukan
            </b-badge>
            <b-badge
              v-else-if="data.scope.item.trkm_hasil_status=='N'"
              variant="danger"
            >
              Batal Dilakukan
            </b-badge>
            <b-badge
              v-else-if="data.scope.item.trkm_need_approval=='Y'"
              variant="info"
            >
              Menunggu Persetujuan Pembatalan
            </b-badge>
            <b-badge v-else variant="secondary">
              Belum Dilakukan
            </b-badge>

            <span v-if="data.scope.value" class="d-block mt-1">
              {{ data.scope.value | moment('DD MMM YYYY, HH:mm') }}
            </span>
            <span v-else class="d-block mt-1">-</span>
          </template>
          <template #aksi="data">
            <b-button-group>
              <template v-if="!moduleRole('ViewOnly') && moduleRole('isPJRTU') && data.scope.item.trkm_need_approval=='Y'">
                <b-button variant="success">
                  <div 
                    v-b-tooltip.hover="'Setujui'"
                    @click="doOpenModal('FUModal', data.scope.item)"
                  >
                    <i class="fas fa-check"></i>
                  </div>
                </b-button>
                <b-button variant="danger">
                  <div 
                    v-b-tooltip.hover="'Tolak'"
                    @click="doOpenModal('FUModal', data.scope.item)"
                  >
                    <i class="ti-close"></i>
                  </div>
                </b-button>
              </template>
              <b-button
                v-else-if="
                  !moduleRole('ViewOnly') &&
                  (moduleRole('isRiskOfficer') || moduleRole('isPenaksir') || moduleRole('isAdministratorSystem') || moduleRole('isAuditor')) &&
                  data.scope.item.trkm_need_approval=='N' &&
                  (
                    data.scope.item.trkm_hasil_status=='D' || moduleRole('isAdministratorSystem') || moduleRole('isAuditor')
                  )
                "
                variant="warning"
                @click="doOpenModal('FUModal', data.scope.item)"
              >
                <div v-b-tooltip.hover title="Follow Up">
                <i class="ti-pencil"></i>
                </div>
              </b-button>
              <b-button
                v-else
                variant="info"
                @click="doOpenModal('Detail', data.scope.item)"
              >
                <div v-b-tooltip.hover title="Lihat Hasil">
                  <i class="ti-eye"></i>
                </div>
              </b-button>
            </b-button-group>
          </template>
        </card-list>
      </b-overlay>
    </template>

    <b-modal 
      id="FUModal" 
      :title="modal.mrk_name"
      size="lg"
      :busy="loadingOverlay"
      no-close-on-backdrop
      no-close-on-esc
      :hide-footer="true"
    >
      <b-overlay :show="loadingOverlay">
        <div class="wrap_info_preview">
          <b-row class="mb-3">
            <b-col md=5>
              <div class="wrap_preview_prof">
                <h3>ID Risiko</h3>
                <p>{{ modal.trp_register_code }}</p>
              </div>
            </b-col>
          </b-row>
          <b-row class="mb-3">
            <b-col md=5>
              <div class="wrap_preview_prof">
                <h3>Nama Risiko</h3>
                <p>{{ modal.mr_name }}</p>
              </div>
            </b-col>
            <b-col md=5>
              <div class="wrap_preview_prof">
                <h3>Periode</h3>
                <p v-if="modal.trp_is_periode=='triwulan'"> 
                  Triwulan ke-{{ modal.trp_triwulan }} {{ modal.trp_tahun }}
                </p>
                <p v-else>
                  {{ '2021-'+modal.trp_bulan+'-01' | moment('MMMM') }}-{{ modal.trp_tahun }}
                </p>
              </div>
            </b-col>
          </b-row>
          <b-row class="mb-3">
            <b-col md=5>
              <div class="wrap_preview_prof">
                <h3>Kantor</h3>
                <p> {{modal.mo_code}} - {{ (modal.mo_level||"").toUpperCase() }} - {{modal.mo_name}}</p>
              </div>
            </b-col>
            <b-col md=5>
              <div class="wrap_preview_prof">
                <h3>Unit Kerja</h3>
                <p> {{modal.muk_code}} - {{ (modal.muk_level||"").toUpperCase() }} - {{modal.muk_description}}</p>
              </div>
            </b-col>
          </b-row>
          <b-row class="mb-3">
            <b-col md=5>
              <div class="wrap_preview_prof">
                <h3>Tindakan Mitigasi</h3>
                <p>{{ modal.mrk_name }}</p>
              </div>
            </b-col>
            <b-col md=5>
              <div class="wrap_preview_prof">
                <h3>Jadwal Mitigasi</h3>
                <p>{{ modal.trkm_mitigasi_jadwal_awal | moment('DD MMM YYYY') }} - {{ modal.trkm_mitigasi_jadwal_akhir | moment('DD MMM YYYY') }}</p>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col md=5>
              <div class="wrap_preview_prof">
                <h3>Biaya</h3>
                <p>Rp {{ (modal.trkm_mitigasi_biaya||0).format() }}</p>
              </div>
            </b-col>
            <b-col md=5>
              <div class="wrap_preview_prof">
                <h3>PIC</h3>
                <p>{{ modal.mp_description||'-' }}</p>
              </div>
            </b-col>
          </b-row>
        </div>

        <VTab>
          <template slot="link">
            <VTabLink :step.sync="tab" option="fu">Follow Up</VTabLink>
            <VTabLink :step.sync="tab" option="logs">Logs</VTabLink>
          </template>
          <template slot="content">
            <VTabContent :step.sync="tab" option="fu">
              <b-card v-if="Object.keys(modal).length" class="p-3">

                <template v-if="moduleRole('isPJRTU')">

                  <b-card>
                    <b-form-group label="Alasan Pembatalan:">
                      <p>{{ modal.trkm_hasil_batal_reason }}</p>
                    </b-form-group>
                  </b-card>

                </template>
                <template v-else>
                  <ValidationObserver v-slot="{ handleSubmit }" ref="VFormModal">
                    <form 
                      @submit.prevent="handleSubmit(doSubmitModalValidate('FUModal'))" 
                    >

                      <b-form-group label="Status Follow Up:">
                        <VSelect 
                          v-model="input.status" 
                          placeholder="-- Pilih Satu --"
                          :options="mrStatus.slice(1)" 
                          :reduce="v=>v.value" 
                        />
                        <VValidate 
                          name="Status" 
                          v-model="input.status" 
                          rules="required" 
                        />
                      </b-form-group>

                      <template v-if="input.status=='Y'">
                        
                        <b-row>
                          <b-col md=8>
                            <b-form-group label="Dokumen Mitigasi: ">
                              <template v-if="input.doc">
                                <ImagePreview 
                                  :src="uploader(input.doc)"
                                >
                                  <a href="javascript:;" class="txt_link_download mr-2 d-inline-block"> 
                                    <i class="far fa-file-pdf"></i> Tersedia
                                  </a>
                                </ImagePreview>
                                <Uploader 
                                  v-model="input.doc" 
                                  :no-help="true"
                                  :no-preview="true"
                                  type="document_mitigasi" 
                                  label="Dokumen"
                                >
                                  <a 
                                    slot="btnupload" 
                                    href="javascript:;" 
                                    class="txt_link_download ml-2"
                                  > 
                                    <b-icon icon="cloud-upload" aria-hidden="true"></b-icon> Upload Ulang
                                  </a>
                                  <a 
                                    slot="btnloading" 
                                    href="javascript:;" 
                                    class="txt_link_download text-warning border-warning ml-2"
                                  > 
                                    Uploading...
                                  </a>
                                </Uploader>
                              </template>
                              <template v-else>
                                <Uploader 
                                  v-model="input.doc" 
                                  :no-help="true"
                                  :no-preview="true"
                                  type="document_mitigasi" 
                                  label="Dokumen"
                                />
                                <div class="wrap_info">
                                  <center><small>Format: {{$root.app.uploader.document.rule_type.toUpperCase().split(',').join(', ')}}</small></center>
                                  <center><small>
                                    Maximal ukuran file {{$root.app.uploader["max_image_size"].bytesToSize()}}
                                  </small></center>
                                </div>
                              </template>
                              <VValidate 
                                name="Dokume Mitigasi"
                                v-model="input.doc" 
                                rules="required" 
                              />
                            </b-form-group>
                          </b-col>
                        </b-row>

                        <b-form-group label="Hasil Mitigasi:">
                          <b-textarea v-model="input.hasil"></b-textarea>
                          <VValidate 
                            name="Hasil Mitigasi" 
                            v-model="input.hasil" 
                            rules="required" 
                          />
                        </b-form-group>

                      </template>
                      <template v-else-if="
                        input.status=='N' ||
                        (input.status=='D' && OpenReasonBelumDilakukan)
                      ">
                        <b-form-group label="Alasan:">
                          <b-textarea v-model="input.reason"></b-textarea>
                          <VValidate 
                            name="Alasan" 
                            v-model="input.reason" 
                            rules="required" 
                          />
                        </b-form-group>
                      </template>

                    </form>
                  </ValidationObserver>
                </template>

                <template #footer>
                  <div align="right">
                    <b-button 
                      variant="secondary"
                      class="mr-2"
                      @click="$bvModal.hide('FUModal')"
                    >
                      Batal
                    </b-button>
                    <template v-if="moduleRole('isPJRTU')">
                      <b-button 
                        variant="danger"
                        class="mr-2"
                        @click="doSubmitModal('RejectModal')"
                      >
                        Tolak
                      </b-button>
                      <b-button 
                        variant="success"
                        @click="doSubmitModal('ApproveModal')"
                      >
                        Setujui
                      </b-button>
                    </template>
                    <template v-else>
                      <b-button 
                        variant="success"
                        @click="doSubmitModalValidate('FUModal')"
                        :disabled="input.status==modal.trkm_hasil_status&&input.status!='D'"
                      >
                        Kirim
                      </b-button>
                    </template>
                  </div>
                </template>
              </b-card>
            </VTabContent>
            <VTabContent :step.sync="tab" option="logs">
              <div v-if="modal.logs" class="table-responsive">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Tgl Input</th>
                      <th>Status Folow Up</th>
                      <th>Alasan</th>
                    </tr>
                  </thead>
                  <tbody v-if="!modal.logs.length">
                    <tr>
                      <th colspan="5"><center>Tidak ada logs.</center></th>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr v-for="(v,k) in modal.logs" :key="k">
                      <td>{{ k+1 }}</td>
                      <td>{{ v.trkml_created_date | moment('DD MMM YYYY, HH:mm') }}</td>
                      <td>
                        <span v-if="v.trkml_status=='Y'" class="badge badge-success">
                          Sudah Dilakukan
                        </span>
                        <span v-else-if="v.trkml_status=='N'" class="badge badge-danger">
                          Batal Dilakukan
                        </span>
                        <span v-else class="badge badge-warning">
                          Belum Dilakukan
                        </span>
                      </td>
                      <td>{{ v.trkml_reason||'-' }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </VTabContent>
          </template>
        </VTab>
      </b-overlay>
    </b-modal>

    <b-modal 
      id="Detail"
      :title="modal.mrk_name"
      size="lg"
      :busy="loadingOverlay"
      no-close-on-backdrop
      no-close-on-esc
      :hide-footer="true"
    >
      <b-overlay :show="loadingOverlay">
        <div class="wrap_info_preview">
          <b-row class="mb-3">
            <b-col md=5>
              <div class="wrap_preview_prof">
                <h3>ID Risiko</h3>
                <p>{{ modal.trp_register_code }}</p>
              </div>
            </b-col>
          </b-row>
          <b-row class="mb-3">
            <b-col md=5>
              <div class="wrap_preview_prof">
                <h3>Nama Risiko</h3>
                <p>{{ modal.mr_name }}</p>
              </div>
            </b-col>
            <b-col md=5>
              <div class="wrap_preview_prof">
                <h3>Periode</h3>
                <p v-if="modal.trp_is_periode=='triwulan'"> 
                  Triwulan ke-{{ modal.trp_triwulan }} {{ modal.trp_tahun }}
                </p>
                <p v-else>
                  {{ '2021-'+modal.trp_bulan+'-01' | moment('MMMM') }}-{{ modal.trp_tahun }}
                </p>
              </div>
            </b-col>
          </b-row>
          <b-row class="mb-3">
            <b-col md=5>
              <div class="wrap_preview_prof">
                <h3>Nama Mitigasi</h3>
                <p>{{ modal.mrk_name }}</p>
              </div>
            </b-col>
            <b-col md=5>
              <div class="wrap_preview_prof">
                <h3>Jadwal Mitigasi</h3>
                {{ modal.trkm_mitigasi_jadwal_awal | moment('DD MMM YYYY') }} - {{ modal.trkm_mitigasi_jadwal_akhir | moment('DD MMM YYYY') }}
              </div>
            </b-col>
          </b-row>
          <b-row class="mb-3">
            <b-col md=5>
              <div class="wrap_preview_prof">
                <h3>Biaya</h3>
                <p>Rp {{(modal.trkm_mitigasi_biaya||0).format()}}</p>
              </div>
            </b-col>
            <b-col md=5>
              <div class="wrap_preview_prof">
                <h3>PIC</h3>
                <p>{{ modal.mp_description }}</p>
              </div>
            </b-col>
          </b-row>
          <b-row class="mb-3">
            <b-col md=5>
              <div class="wrap_preview_prof">
                <h3>Kantor</h3>
                <p> {{modal.mo_code}} - {{ (modal.mo_level||"").toUpperCase() }} - {{modal.mo_name}}</p>
              </div>
            </b-col>
            <b-col md=5>
              <div class="wrap_preview_prof">
                <h3>Unit Kerja</h3>
                <p> {{modal.muk_code}} - {{ (modal.muk_level||"").toUpperCase() }} - {{modal.muk_description}}</p>
              </div>
            </b-col>
          </b-row>
          <b-row v-if="modal.trkm_mitigasi_doc">
            <b-col md=5>
              <div class="wrap_preview_prof">
                <h3>Dokumen Mitigasi</h3>
                <p> 
                  <ImagePreview 
                    :src="uploader(modal.trkm_mitigasi_doc)"
                  >
                    <a 
                      href="javascript:;" class="txt_link_download mb-3 d-inline-block"
                    > 
                      <i class="far fa-file-pdf"></i> Tersedia
                    </a>
                  </ImagePreview>
                </p>
              </div>
            </b-col>
            <b-col md=5>
              <div class="wrap_preview_prof">
                <h3>Hasil Mitigasi</h3>
                <p> {{ modal.trkm_mitigasi_hasil }}</p>
              </div>
            </b-col>
          </b-row>
        </div>

        <div v-if="modal.logs" class="table-responsive mt-3">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>#</th>
                <th>Tgl Input</th>
                <th>Status Folow Up</th>
                <th>Alasan</th>
              </tr>
            </thead>
            <tbody v-if="!modal.logs.length">
              <tr>
                <th colspan="5"><center>Tidak ada logs.</center></th>
              </tr>
            </tbody>
            <tbody v-else>
              <tr v-for="(v,k) in modal.logs" :key="k">
                <td>{{ k+1 }}</td>
                <td>{{ v.trkml_created_date | moment('DD MMM YYYY, HH:mm') }}</td>
                <td>
                  <span v-if="v.trkml_status=='Y'" class="badge badge-success">
                    Sudah Dilakukan
                  </span>
                  <span v-else-if="v.trkml_status=='N'" class="badge badge-danger">
                    Batal Dilakukan
                  </span>
                  <span v-else class="badge badge-warning">
                    Belum Dilakukan
                  </span>
                </td>
                <td>{{ v.trkml_reason||'-' }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-overlay>
    </b-modal>
  </div>
</div>
</template>

<script>
import Gen from '@/libs/Gen'
import GlobalVue from '@libs/Global'
import PageTitle from '@view/include/PageTitle'
import CardList from '@/components/CardList'

import VTab from '@/components/VTab/VTab.vue'
import VTabLink from '@/components/VTab/VTabLink.vue'
import VTabContent from '@/components/VTab/VTabContent.vue'

let _ = global._
let Swal = global.Swal
export default{
  extends:GlobalVue,
  components:{
    PageTitle, CardList,
    VTab, VTabLink, VTabContent
  },
  data(){
    return {
      tab:'fu',
      idKey:'trkm_id',
      fields:[
        {
          key: 'number', label: '#'
        },
        {
          key: 'mrk_name', label: 'Mitigasi'
        },
        {
          key: 'trkm_mitigasi_biaya', label: 'Biaya', is_custom:true
        },
        {
          key: 'jadwal', label: 'Jadwal Mitigasi', is_custom:true
        },
        {
          key: 'mp_description', label: 'PIC'
        },
        {
          key: 'trkm_hasil_input_date', label: 'Follow Up Terakhir', is_custom:true, tdClass:"max_w_200"
        },
        {
          key:'aksi', is_custom:true, style:{width:'170px'}
        }
      ],
      modal:{},
      input:{},

      mrStatus:[
        {label:'Belum Ada Aksi', value:'need-action'},
        {label:'Belum Dilakukan', value:'D'},
        {label:'Batal Dilakukan', value:'N'},
        {label:'Sudah Dilakukan', value:'Y'},
      ]
    }
  },
  computed:{
    OpenReasonBelumDilakukan(){
      return this.$moment(this.modal.trkm_mitigasi_jadwal_akhir).format('YYYY-MM-DD') < this.$moment().format('YYYY-MM-DD')
    }
  },
  methods:{
    doOpenModal(id, data){
      this.$set(this,'input',{})
      
      if(this.moduleRole('isAdministratorSystem') || this.moduleRole('isAuditor')){
        this.$set(this.input, 'status', data.trkm_hasil_status);
        this.$set(this.input, 'doc', data.trkm_mitigasi_doc);
        this.$set(this.input, 'hasil', data.trkm_mitigasi_hasil);
      }

      this.$set(this,'modal', _.clone(data))
      this.$set(this, 'tab', 'fu')
      this.$bvModal.show(id)
    },
    doOKModal(bvModalEvt, id){
      bvModalEvt.preventDefault()
      if(['ReviseModal','RejectModal'].indexOf(id)>-1 && !this.input.reason){
        return Swal.fire({
            icon: "warning",
            title: "Detail "+(id=='ReviseModal'?'Revisi':'Penolakan')+" Wajib Diisi!",
            confirmButtonText: "Mengerti"
          })
      }
      this.doSubmitModal(id)
    },
    doSubmitModalValidate(id){
      this.$refs['VFormModal'].validate().then(success => {
        if (!success) { return; }

        this.doSubmitModal(id)
        
      })
    },
    doSubmitModal(id){
      this.loadingOverlay = true
      Gen.apiRest(
        "/do/"+this.modulePage,
        {
          data:Object.assign(this.input, {
            type:"Do"+id,
            id:this.modal[this.idKey]
          })
        },
        'POST'
      ).then((res)=>{
        this.loadingOverlay=false
        this.$bvModal.hide(id)
        this.$bvModal.hide("Detail")
        this.$bvModal.hide("FUModal")
        let resp = res.data
        if(Object.keys(this.$route.query).length == 1){
          this.apiGet()   
        }else {
          this.$set(this, 'filter', {})
          this.$router.push({name:this.modulePage, query:{tab:this.$route.query.tab}})
        }
        global.Swal.fire({
          title: resp.title,
          text: resp.message||"",
          icon: resp.status,
        })
      })
    },
  },
  mounted(){
    this.apiGet()
  },
  watch:{
    $route(){
      this.apiGet()
    }
  }
}
</script>

<style scoped>
.txt_link_download{
  border: none;
  margin-bottom: 1em;
}
</style>